import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import PdfButton from "../components/PdfButton"
import {
  certificationEipTest,
  requirementsEip,
  eipDocuments,
} from "../data/certification-efp"
import parse from "react-html-parser"
import Iva from "../components/Iva"
import TitlePage from "../components/TitlePage"
import { useAxiosClient } from "../service/useAxiosClient"
import AnnouncementEfp from "../components/Announcement/AnnouncementEfp"
import TutorialBtn from "../components/TutorialBtn"

export default function CertificationTestEIP({ location }) {
  const id = 6

  const [test, setTest] = useState()
  const [price, setPrice] = useState()

  const [{ data: dataTest }] = useAxiosClient({
    url: `/exams/dates/web/next-calls/?exam_type=EIP`,
    method: "GET",
  })

  const [{ data: dataPrice }] = useAxiosClient({
    url: `/professional-certificates/web/exam-price/?professional_certificate=${id}`,
    method: "GET",
  })

  useEffect(() => {
    if (dataPrice) {
      setPrice(dataPrice)
    }
  }, [dataPrice])

  useEffect(() => {
    if (dataTest && dataTest.EIP) {
      const [test] = dataTest.EIP.calls
      setTest(test)
    }
  }, [dataTest])

  const path = [
    { label: "Inicio", to: "/" },
    { label: "Certificaciones", to: "/certificacion" },
    {
      label:
        "Exámenes certificación EFPA <em>European Investment Practitioner</em> (EIP)",
    },
  ]
  const renderContent = () => {
    return (
      certificationEipTest.length &&
      certificationEipTest.map(item => {
        return (
          <div className="efpa-content">
            <TitleBlock
              className="Certification-title-block"
              title={item.title}
              icon={item.icon}
            />
            <div className="Certification-container">{parse(item.content)}</div>
          </div>
        )
      })
    )
  }

  const renderContentRight = () => {
    return (
      requirementsEip.length &&
      requirementsEip.map(item => {
        return (
          <div className="efpa-content">
            <TitleBlock
              className="Certification-title-block"
              title={item.title}
              icon={item.icon}
            />
            <div className="Certification-container">{parse(item.content)}</div>
          </div>
        )
      })
    )
  }

  const renderDocuments = () => {
    return (
      eipDocuments &&
      eipDocuments.map(item => {
        return (
          <PdfButton
            isExternal
            link={item.link}
            className="mb-2"
            text={item.name}
          />
        )
      })
    )
  }

  const renderNextTest = () => {
    if (!test) return null
    return (
      <div className="Certification-content">
        <AnnouncementEfp
          title={test.call_text}
          date={test.exams_date_text}
          link={test.inscription_link}
        />
      </div>
    )
  }

  return (
    <Layout>
      <TitlePage
        title="Exámenes certificación EFPA European Investment Practitioner (EIP)"
        url={location.pathname}
      />
      <Hero image="/imgs/certificaciones-hero.jpg" text="Certificaciones" />
      <div className="container950">
        <Breadcrumb path={path} />
        <TitleBlock
          title="Exámenes certificación EFPA <em>European Investment Practitioner</em> (EIP)"
          icon="far fa-file-alt"
        />
        <div className="row efpa-row">
          <div className="col-12 col-md-6 Certification-padding-r">
            {renderContent()}
            <TutorialBtn />
          </div>
          <div className="col-12 col-md-6 Certification-padding-l">
            {renderContentRight()}
            <div className="efpa-content">
              <TitleBlock
                className="Certification-title-block"
                title="Documentación"
                icon="far fa-file-alt"
              />
              <div className="Certification-content">{renderDocuments()}</div>
            </div>
            <div className="Certification-content">{renderNextTest()}</div>

            <div className="efpa-content">
              <TitleBlock
                className="Certification-title-block"
                title="Coste"
                icon="d-none"
              />
              <Iva
                price={`${price} € (IVA incluido)`}
                text="Consultar en EFPA los descuentos para entidades colaboradoras."
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
