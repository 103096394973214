import React from "react"
import PropTypes from "prop-types"

const TutorialBtn = props => {
  return (
    <div className={props.className || "efpa-content"}>
      <a href="https://www.efpa.es/videotutorial-examen-online">
        <img
          alt="tutorial link"
          src="/imgs/boton-videotutorial-examenesonline.jpg"
        />
      </a>
    </div>
  )
}

TutorialBtn.propTypes = {
  className: PropTypes.string,
}

export default TutorialBtn
